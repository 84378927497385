
import { defineComponent, ref, Ref } from 'vue'
import Steps from 'primevue/steps'
import { StepsEvent } from '@/models/interfaces/primevue'
import { useRouter, useRoute } from 'vue-router'
import { CreateJobOfferDto } from '@/models/jobBank/interfaces/jobOffer'
import { JobOffersAPI } from '@/api/job-offers.api'
import { useToast } from 'primevue/usetoast'
import { JobBankRouteNames } from '@/router/route-names'

export default defineComponent({
  components: {
    Steps
  },
  props: {
    formOfferData: {
      type: Object
    },
    isEditing: {
      type: Boolean
    },
    city: {
      required: true,
      type: Object,
      default: null
    },
    company: {
      required: true,
      type: Object,
      default: null
    },
    modality: {
      required: true,
      type: String,
      default: null
    },
    hierarchy: {
      required: true,
      type: String,
      default: null
    },
    area: {
      required: true,
      type: String,
      default: null
    },
    endDate: {
      required: true,
      type: Date,
      default: null
    },
    publishDate: {
      required: true,
      type: Date,
      default: null
    }
  },
  setup (props) {
    const router = useRouter()
    const route = useRoute()
    const { jobOfferId } = route.params
    const loading = ref(false)
    const getJobOffer = async () => {
      loading.value = true
      const { __v, ...rest } = await JobOffersAPI.findJobOffer(jobOfferId as string)
      formOfferObject.value = rest
      loading.value = false
    }
    if (jobOfferId) {
      getJobOffer()
    }
    const formObject: Ref<any> = ref({
      name: '',
      modalityId: '',
      companyId: '',
      quantityEmployees: undefined,
      areaId: '',
      typeCompanyId: '',
      hierarchyId: '',
      link: '',
      cityId: '',
      publishDate: null,
      endDate: null
    })

    const formOfferObject: Ref<any> = ref({
      name: '',
      modalityId: '',
      companyId: '',
      quantityEmployees: undefined,
      areaId: '',
      typeCompanyId: '',
      hierarchyId: '',
      link: '',
      cityId: '',
      publishDate: null,
      endDate: null,
      company: { name: '', sector: '', logo: '', description: '', contacts: [] },
      city: {}
    })
    let offerInfo = ''
    let companyInfo = ''
    let publish = ''

    let currentOfferId: string | null = null

    if (props.isEditing) {
      currentOfferId = jobOfferId as string
      offerInfo = `/job-bank/job-offers/view/${jobOfferId}/edit/offer-info`
      companyInfo = `/job-bank/job-offers/view/${jobOfferId}/edit/company-info`
      publish = `/job-bank/job-offers/view/${jobOfferId}/edit/publish`
      formOfferObject.value = {
        name: props.formOfferData?.name,
        modalityId: props.formOfferData?.modalityId,
        companyId: props.company?._id,
        quantityEmployees: props.formOfferData?.quantityEmployees,
        areaId: props.formOfferData?.areaId,
        typeCompanyId: props.formOfferData?.typeCompanyId,
        hierarchyId: props.formOfferData?.hierarchyId,
        link: props.formOfferData?.link,
        cityId: props.formOfferData?.cityId,
        publishDate: props.publishDate,
        endDate: props.endDate,
        company: {
          name: props.company?.name,
          sector: props.company?.sector,
          logo: props.company?.logo,
          description: props.company?.description,
          contacts: []
        },
        city: props.city
      }
    } else {
      offerInfo = '/job-bank/job-offers/create/offer-info'
      companyInfo = '/job-bank/job-offers/create/company-info'
      publish = '/job-bank/job-offers/create/publish'
    }

    const items = ref([
      {
        label: 'Información de la oferta',
        to: offerInfo,
        command: ({ originalEvent }:{originalEvent:any}) => {
          if (currentPage.value > 0) {
            currentPage.value = 0
          } else {
            originalEvent.preventDefault()
          }
        }
      },
      {
        label: 'Información de la empresa',
        to: companyInfo,
        command: ({ originalEvent }:{originalEvent:any}) => {
          if (currentPage.value > 1) {
            currentPage.value = 1
          } else {
            originalEvent.preventDefault()
          }
        }
      },
      {
        label: 'Publicar',
        to: publish,
        command: ({ originalEvent }:{originalEvent:any}) => {
          if (currentPage.value > 2) {
            currentPage.value = 2
          } else {
            originalEvent.preventDefault()
          }
        }
      }
    ])
    const currentPage = ref(0)

    const nextPage = async (event: StepsEvent) => {
      if (event.pageIndex === 2) {
        for (const field in event.formOfferData) {
          formOfferObject.value[field as keyof CreateJobOfferDto] = event.formOfferData[field as keyof CreateJobOfferDto]! as any
        }
        if (props.isEditing) {
          JobOffersAPI.updateJobOffer(formOfferObject.value, jobOfferId as string)
          router.push({ name: JobBankRouteNames.EDIT_PUBLISHEDJOBOFFER })
        } else {
          const { _id } = await JobOffersAPI.createJobOffer(formOfferObject.value)
          currentOfferId = _id
          router.push({ name: JobBankRouteNames.PUBLISHEDJOBOFFER })
        }
        return
      }
      for (const field in event.formOfferData) {
        formOfferObject.value[field as keyof CreateJobOfferDto] = event.formOfferData[field as keyof CreateJobOfferDto]! as any
      }
      router.push(items.value[event.pageIndex + 1].to)
      currentPage.value = event.pageIndex + 1
    }

    const prevPage = (event: StepsEvent) => {
      if (event.pageIndex === 0) {
        router.push({ name: JobBankRouteNames.JOB_OFFERS })
        return
      }
      router.push(items.value[event.pageIndex - 1].to)
      currentPage.value = event.pageIndex - 1
    }

    const toast = useToast()

    const saveChanges = async (event: StepsEvent) => {
      for (const field in event.formOfferData) {
        formOfferObject.value[field as keyof CreateJobOfferDto] = event.formOfferData[field as keyof CreateJobOfferDto]! as any
      }
      if (currentOfferId === null) {
        const { _id } = await JobOffersAPI.createIncompleteJobOffer(formOfferObject.value)
        currentOfferId = _id
        toast.add({ severity: 'success', detail: 'Los campos completados se han guardado correctamente.', life: 3500 })
      } else {
        JobOffersAPI.updateJobOffer(formOfferObject.value, currentOfferId)
        toast.add({ severity: 'success', detail: 'Los campos completados se han guardado correctamente.', life: 3500 })
      }
    }

    const handleStatusChange = () => {
      openModal()
    }

    const displayModal = ref(false)
    const openModal = () => {
      displayModal.value = true
    }

    const closeModal = () => {
      displayModal.value = false
    }

    const unPublish = () => {
      formOfferObject.value.publishDate = null
      formOfferObject.value.endDate = null
      if (props.isEditing) {
        JobOffersAPI.updateJobOffer(formOfferObject.value, jobOfferId as string)
      } else {
        JobOffersAPI.updateJobOffer(formOfferObject.value, currentOfferId as string)
      }
      closeModal()
      toast.add({ severity: 'success', detail: '¡Listo! Se dejó de publicar la oferta laboral.', life: 3500 })
      router.push({ name: JobBankRouteNames.JOB_OFFERS })
    }

    return {
      items,
      nextPage,
      prevPage,
      saveChanges,
      formOfferObject,
      loading,
      handleStatusChange,
      openModal,
      closeModal,
      displayModal,
      unPublish
    }
  }
})
