import { Api } from '@/api/api'
import {
  CreateJobOfferDto,
  IJobOffer,
  IJobOffersResponse,
  IJobOfferResponse
} from '@/models/jobBank/interfaces/jobOffer'
class JobOffersApi {
  private readonly baseURL: string

  constructor() {
    this.baseURL = process.env.TALENT_API_ENDPOINT
  }

  public async createJobOffer(job: CreateJobOfferDto): Promise<IJobOffer> {
    return Api.post(`${this.baseURL}/jobOffers`, job)
  }

  public async createIncompleteJobOffer(
    job: Partial<CreateJobOfferDto>
  ): Promise<IJobOffer> {
    return Api.post(`${this.baseURL}/jobOffers`, job)
  }

  public async updateJobOffer(
    job: Partial<CreateJobOfferDto>,
    currentOfferId: string
  ): Promise<IJobOffer> {
    return Api.put(`${this.baseURL}/jobOffers/${currentOfferId}`, {
      ...job,
      currentOfferId
    })
  }

  public async findAll(
    page = 1,
    limit = 25,
    search = '',
    status = ''
  ): Promise<IJobOffersResponse> {
    return Api.get(
      `${this.baseURL}/jobOffers?page=${page}&limit=${limit}&search=${search}&status=${status}`
    )
  }

  public async findAllFromScraperPaginated(
    page = 1,
    limit = 25
  ): Promise<IJobOffersResponse> {
    return Api.get(
      `${this.baseURL}/jobOffers/findAllFromScraperPaginated?page=${page}&limit=${limit}`
    )
  }

  public async findJobOffersByCompanyId(
    companyId: string
  ): Promise<IJobOffersResponse> {
    return Api.get(`${this.baseURL}/jobOffers?companyId=${companyId}`)
  }

  public async createArea(area: { value: string }): Promise<{ value: string }> {
    return Api.post(`${this.baseURL}/jobOfferDetails/areas`, area)
  }

  public async getOptionsByType(type: string): Promise<any[]> {
    return Api.get(`${this.baseURL}/jobOfferDetails?type=${type}`)
  }

  public async findJobOffer(id: string): Promise<IJobOfferResponse> {
    return Api.get(`${this.baseURL}/jobOffers/${id}`)
  }

  public async findAllJobOffers(): Promise<IJobOffersResponse[]> {
    return Api.get(`${this.baseURL}/jobOffers/report`)
  }

  public async publishJob(data: IJobOffer): Promise<any> {
    return Api.put(`${this.baseURL}/jobOffers/${data._id}`, data)
  }
}

export const JobOffersAPI = new JobOffersApi()
